body {
  background-color: #000;
  color: #fff; }

.text-rarity1 {
  color: #999; }

.text-rarity2 {
  color: #156831; }

.text-rarity3 {
  color: #1a509c; }

.text-rarity4 {
  color: #893088; }

.text-rarity5 {
  color: #b89d27; }

.champion-tile {
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  margin: 0 12px 4px 4px; }
  .champion-tile button {
    border: 0;
    background: none;
    outline: none;
    color: #fff;
    padding: 0;
    text-align: left;
    cursor: pointer; }
  .champion-tile .img {
    width: 100%;
    aspect-ratio: 1;
    background-size: 200%;
    background-position: 100% 0; }
  .champion-tile.selected .img {
    filter: none; }
  .champion-tile h4 {
    font-size: 13px;
    padding: 2px 0;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 100%; }
  .champion-tile small {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 100%;
    font-size: 11px; }

#team-builder .champions {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  border-bottom: 1px solid #333; }
  #team-builder .champions .champion-tile {
    flex: 0 0 auto;
    margin: 0 12px 4px 4px; }
    #team-builder .champions .champion-tile .img {
      margin: 4px 0 0;
      filter: grayscale(0.85); }
    #team-builder .champions .champion-tile.selected .img {
      filter: none; }

#team-builder .team {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  border-bottom: 1px solid #333; }
  #team-builder .team .champion-tile {
    margin: 8px 12px 8px 0; }

#team-builder .traits {
  border-bottom: 1px solid #333;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  line-height: 1.75rem; }
